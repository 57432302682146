'use client'
import { sendGTMEvent } from '@next/third-parties/google'
import { useParams, usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

export interface BuyersAlertTrackerType {
  buyers_alert_card_id?: string
  buyers_alert_card_clicked_id?: string
  buyers_alert_card_lang: string
  buyers_alert_card_company_id: string
  userId: number
}

export const useGTMBuyerTracker = (companyId: number, userId: number) => {
  const pathName = usePathname()
  const params = useParams()
  const [pageLoadTime, setPageLoadTime] = useState<number | null>(null)

  useEffect(() => {
    const startTime = Date.now()
    setPageLoadTime(startTime)

    return () => {
      if (companyId && pageLoadTime !== null) {

        sendGTMEvent({
          event: 'buyer_alert_detail_visit',
          buyers_alert_id: params?.buyerAlertId,
          company_id: companyId,
          lang: params?.lng ?? 'en',
          current_user_id: userId>0 ? String(userId) : 'non-logged-in',
        })
      }
    }
  }, [pathName, companyId])
}

export const useContactBuyerBuyerAlertGTMEvent = () => {
  const sendContactBuyerEvent = ({
    buyerAlertID,
    companyID,
  }: {
    buyerAlertID: number
    companyID: number
  }) => {
    sendGTMEvent({
      event: 'buyer_alert_contact_buyer_click',
      buyers_alert_id: buyerAlertID,
      company_id: companyID,
    })
  }

  return sendContactBuyerEvent
}

export const GTMABACardViewTracker = ({
  buyers_alert_card_id,
  buyers_alert_card_lang,
  buyers_alert_card_company_id,
  userId,
}: BuyersAlertTrackerType) => {
  sendGTMEvent({
    event: 'buyers_alert_card_view',
    buyers_alert_id: buyers_alert_card_id,
    lang: buyers_alert_card_lang,
    company_id: buyers_alert_card_company_id,
    current_user_id: userId > 0 ? String(userId) : 'non_logged_in',
  })
}

export const GTMABACardClickTracker = ({
  buyers_alert_card_clicked_id,
  buyers_alert_card_lang,
  buyers_alert_card_company_id,
  userId,
}: BuyersAlertTrackerType) => {
  sendGTMEvent({
    event: 'buyers_alert_card_clicked',
    buyers_alert_id: buyers_alert_card_clicked_id,
    lang: buyers_alert_card_lang,
    company_id: buyers_alert_card_company_id,
    current_user_id: userId > 0 ? String(userId) : 'non_logged_in',
  })
}
