'use client'
import { RootState } from '@/app/globalRedux/store'
import { sendGTMEvent } from '@next/third-parties/google'
import { useParams, usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface SalesOfferTrackerType {
  sales_offer_card_id?: string
  sales_offer_card_clicked_id?: string
  sales_offer_card_lang: string
  sales_offer_card_company_id: string
  userId: number
}

export const useGTMSalesOfferTracker = (companyId: number) => {
  const pathName = usePathname()
  const params = useParams()
  const [pageLoadTime, setPageLoadTime] = useState<number | null>(null)
  const userId = useSelector((state: RootState) => state.user.userId)
  useEffect(() => {
    const startTime = Date.now()
    setPageLoadTime(startTime)

    return () => {
      if (companyId && pageLoadTime !== null) {
        sendGTMEvent({
          event: 'sales_offer_detail_visit',
          sales_offer_id: params?.salesOfferId,
          lang: params?.lng ?? 'en',
          company_id: companyId,
          current_user_id: userId > 0 ? String(userId) : 'non-logged-in',
        })
      }
    }
  }, [pathName, companyId])
}

export const useContactSellerSalesOfferGTMEvent = () => {
  const params = useParams()
  const sendContactSellerEvent = ({ companyID }: { companyID: number }) => {
    sendGTMEvent({
      event: 'sales_offer_contact_seller_click',
      sales_offer_id: params?.salesOfferId,
      company_id: companyID,
    })
  }

  return sendContactSellerEvent
}

export const GTMASOCardViewTracker = ({
  sales_offer_card_id,
  sales_offer_card_lang,
  sales_offer_card_company_id,
  userId,
}: SalesOfferTrackerType) => {
  sendGTMEvent({
    event: 'sales_offer_card_view',
    sales_offer_id: sales_offer_card_id,
    lang: sales_offer_card_lang,
    company_id: sales_offer_card_company_id,
    current_user_id: userId > 0 ? String(userId) : 'non-logged-in',
  })
}

export const GTMSOCardClickTracker = ({
  sales_offer_card_clicked_id,
  sales_offer_card_lang,
  sales_offer_card_company_id,
  userId,
}: SalesOfferTrackerType) => {
  sendGTMEvent({
    event: 'sales_offer_card_clicked',
    sales_offer_id: sales_offer_card_clicked_id,
    lang: sales_offer_card_lang,
    company_id: sales_offer_card_company_id,
    current_user_id: userId > 0 ? String(userId) : 'non-logged-in',
  })
}
