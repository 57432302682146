import {
  GTMASOCardViewTracker,
  GTMSOCardClickTracker,
} from '@/app/[lng]/components/GTMTrackers/GTMSalesOfferTracker'
import { RootState } from '@/app/globalRedux/store'
import { useIntersectionObserver } from '@/app/hooks/useIntersectionObserver'
import useLocalizedRouter from '@/app/hooks/useLocalizedRouter'
import { useTranslation } from '@/app/i18n/client'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import Image from 'next/image'
import { useParams } from 'next/navigation'
import { MutableRefObject, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  GTMABACardClickTracker,
  GTMABACardViewTracker,
} from '../GTMTrackers/GTMBuyerTracker'

const ShortListingCard = ({
  cardData,
  cardType,
}: {
  cardData: any
  cardType: string
}) => {
  const { t } = useTranslation()
  const params = useParams<{ lng: string }>()
  const userId = useSelector((state: RootState) => state.user.userId)
  const pushWithLocale = useLocalizedRouter()
  const handleClick = () => {
    if (cardType === 'Seller') {
      GTMSOCardClickTracker({
        sales_offer_card_clicked_id: cardData.id,
        sales_offer_card_lang: params?.lng,
        sales_offer_card_company_id: cardData?.company?.id,
        userId: userId,
      })
      pushWithLocale(`/sales_offers/${cardData.id}`)
    } else if (cardType === 'Buyer') {
      GTMABACardClickTracker({
        buyers_alert_card_clicked_id: cardData.id,
        buyers_alert_card_lang: params?.lng,
        buyers_alert_card_company_id: cardData?.company?.id,
        userId: userId,
      })
      pushWithLocale(`/buyers_alert/${cardData.id}`)
    }
  }

  const buyersAlertImage = cardData?.images[0]?.url ?? ''

  const handleSOorBAIntersection = () => {
    if (cardData.id) {
      if (cardType === 'Seller') {
        GTMASOCardViewTracker({
          sales_offer_card_id: cardData.id,
          sales_offer_card_lang: params?.lng,
          sales_offer_card_company_id: cardData?.company?.id,
          userId: userId,
        })
      } else {
        GTMABACardViewTracker({
          buyers_alert_card_id: cardData.id,
          buyers_alert_card_lang: params?.lng,
          buyers_alert_card_company_id: cardData?.company?.id,
          userId: userId,
        })
      }
    }
  }

  const miniCardRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const observeElement = useIntersectionObserver(handleSOorBAIntersection)
  useEffect(() => {
    if (miniCardRef.current) {
      observeElement(miniCardRef.current)
    }
  }, [observeElement])

  return (
    <div
      ref={miniCardRef}
      className={`relative cursor-pointer w-[300px] mobile-width flex flex-col gap-4 rounded-sm border overflow-hidden ${cardType === 'Seller' ? 'border-lightgreen_border bg-light_green hover:border-green_border' : 'border-lightorange_border bg-light_orange hover:border-orange'}`}
      onClick={() => {
        handleClick()
      }}
    >
      <div className={`relative w-full h-[11rem] overflow-hidden bg-[#ededed]`}>
        <Image
          loading="lazy"
          width={296}
          height={176}
          className={`${buyersAlertImage ? 'bg-cover' : 'bg-contain'} bg-no-repeat w-full h-full rounded-t-sm`}
          src={
            buyersAlertImage
              ? buyersAlertImage
              : '/assets/Icons/noImage/noImage2.svg'
          }
          alt=""
        />
        <span
          className={`text-sm w-fit rounded-l-sm text-white absolute py-1 px-3 right-0 bottom-0 ${cardType === 'Seller' ? 'bg-secondary' : 'bg-orange_tag'}`}
        >
          {cardType === 'Seller' ? t('OFFER') : t('ALERT')}
        </span>
      </div>
      <div className="w-[300px] gap-1 p-4 pt-0 overflow-hidden relative">
        <p className="text-sm">
          {cardType === 'Buyer' && cardData?.publication_date
            ? cardData.publication_date
            : cardData?.created_at
              ? cardData.created_at
              : t('date not available')}
        </p>
        <h2 className="font-ns_bold text-lg text-deep_black flex gap-3 mb-4 mobile:text-[16px] mobile:mb-[0px]">
          {cardType === 'Seller' ? (
            <>
              {cardData?.product?.name ?? ''}{' '}
              {cardData?.varieties?.length > 0
                ? ` - ${cardData?.varieties[0]?.name}`
                : ''}{' '}
              {cardData?.origin
                ? `- ${t('Origin')} ${cardType === 'Seller' ? cardData?.origin?.name : cardData?.origin}`
                : ''}
            </>
          ) : (
            <>
              {t('Importer from')}{' '}
              {cardData?.delivery_country?.name
                ? cardData?.delivery_country?.name
                : cardData?.company?.country}{' '}
              {t('is looking for')} {cardData?.product?.name ?? ''}
            </>
          )}
        </h2>
        {cardData?.product_type?.length > 0 ? (
          <div className="pb-2">
            <span className="text-sm border bg-chip_bg border-chip_border rounded-full  px-2">
              {t(
                cardData?.product_type?.length === 2
                  ? cardData?.product_type?.join(' & ')
                  : cardData?.product_type?.[0]
              )}
            </span>
          </div>
        ) : (
          ''
        )}
        <div className="flex flex-col gap-1 mt-1.5 font-ns_bold text-sm">
          {cardType === 'Buyer' ? (
            <span className="font-ns_bold flex gap-1">
              {t('Origin')}:
              <span className="font-light flex">
                {cardData?.origins?.length == 0 && t('NA')}
                {cardData?.origins?.length > 0 && (
                  <span className=" flex flex-row">
                    {cardData?.origins?.[0]?.name}
                    {cardData?.origins?.length > 1 && (
                      <Tooltip
                        title={String(
                          cardData?.origins
                            ?.slice(1, cardData?.origins?.length)
                            .map((item: any) => item.name)
                        )}
                        arrow
                      >
                        <p className=" cursor-pointer ml-1">{` +${cardData?.origins?.length - 1}`}</p>
                      </Tooltip>
                    )}
                  </span>
                )}
              </span>
            </span>
          ) : (
            ''
          )}
          <p className="font-ns_bold truncate max-w-60">
            {t('Caliber')}:{' '}
            <span className="font-light">
              {cardData?.size ? `${cardData.size}` : t('NA')}
            </span>
          </p>
          {cardType !== 'Buyer' ? (
            <span className="font-ns_bold flex">
              {t('Quality')}:
              <span className="font-light flex ml-1">
                {cardData?.quality?.length == 0 && t('NA')}
                {cardData?.quality?.length > 0 && (
                  <span className=" flex flex-row">
                    {cardData?.quality?.[0]?.name}
                    {cardData?.quality?.length > 1 && (
                      <Tooltip
                        title={String(
                          cardData?.quality
                            ?.slice(1, cardData?.quality?.length)
                            .map((item: any) => item.name)
                        )}
                        arrow
                      >
                        <p className=" cursor-pointer ml-1">{` +${cardData?.quality?.length - 1}`}</p>
                      </Tooltip>
                    )}
                  </span>
                )}
              </span>
            </span>
          ) : (
            ''
          )}
          <p className="font-ns_bold flex gap-1">
            {t('Quantity')}:{' '}
            <span className="font-light flex">
              {cardType === 'Buyer' ? (
                <span className=" flex  font-light">
                  {cardData?.quantity_from ? cardData?.quantity_from : t('NA')}
                  <span className="mx-1">
                    {' '}
                    {cardData?.quantity_to
                      ? `${t('to')} ${cardData?.quantity_to}`
                      : ''}{' '}
                  </span>
                </span>
              ) : cardData?.quantity > 0 ? (
                cardData?.quantity
              ) : (
                t('NA')
              )}{' '}
              {cardData?.quantity_unit ? cardData?.quantity_unit?.name : ''}
            </span>
          </p>
          {cardType !== 'Buyer' ? (
            <p className="font-ns_bold truncate max-w-60">
              {t('Packaging')} :{' '}
              {cardData.packaging ? (
                <span className="font-light">
                  {cardData?.packaging ? cardData?.packaging : t('NA')}
                </span>
              ) : (
                <span className="font-light">{t('NA')}</span>
              )}
            </p>
          ) : (
            ''
          )}
          {cardType !== 'Buyer' ? (
            <p className="font-ns_bold">
              {t('Price')}:{' '}
              <span className="font-light">
                {cardData?.price_unit?.code ? cardData.price_unit.code : ''}{' '}
                {cardData?.price ? cardData.price : t('NA')}{' '}
                {cardData?.price_per?.name
                  ? ` / ${cardData.price_per.name}`
                  : ''}
              </span>
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
      {cardData?.is_sponsored && (
        <div className="font-ns_semibold absolute -right-[2rem] bottom-[1.4rem] bg-sponsor_color -rotate-45 text-xs flex items-center justify-center leading-none gap-[0.1rem] px-8">
          <Image
            src="/assets/Icons/blackStar.png"
            alt=""
            width={17.4}
            height={17.4}
          />
          {t('Sponsored')}
        </div>
      )}
    </div>
  )
}

export default ShortListingCard
