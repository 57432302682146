'use client'
import { cn } from '@/lib/utils'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary:
          'text-white bg-primary underline-offset-4 hover:underline shadow-[0_1px_6px_0_rgba(0,0,0,0.1)]',

        default:
          'bg-primary text-primary-foreground hover:bg-primary/90 shadow-[0_1px_6px_0_rgba(0,0,0,0.1)]',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90 shadow-[0_1px_6px_0_rgba(0,0,0,0.1)]',
        outline:
          'border border-2 border-secondary text-secondary bg-secondary-foreground shadow-[0_1px_6px_0_rgba(0,0,0,0.1)]',
        secondary:
          'bg-secondary border border-secondary text-secondary-foreground hover:bg-[#6c8f30] shadow-[0_1px_6px_0_rgba(0,0,0,0.1)]',
        ghost:
          'hover:bg-accent hover:text-accent-foreground shadow-[0_1px_6px_0_rgba(0,0,0,0.1)]',
        link: 'text-primary underline-offset-4 hover:underline shadow-[0_1px_6px_0_rgba(0,0,0,0.1)]',
        grayBtn:
          'text-primary bg-[#fff] text-gray_text shadow-[0_1px_6px_0_rgba(0,0,0,0.1)]',
        plain: '',
        smallBtn:
          'text-light_black font-ns_bold bg-pale_gold !border-pale_gold !p-[3.2px] !py-[1.2px] font-10 !rounded-[0px] shadow-[0_1px_6px_0_rgba(0,0,0,0.1)]',
      },
      size: {
        default: ' px-4 py-2 mobile:py-1',
        sx: 'rounded px-4 py-2 mobile:py-1',
        sm: ' rounded px-3',
        xsm: ' rounded p-1',
        desktop: ' rounded-md px-8',
        rounded: ' rounded-[22px] py-2 px-7',
        icon: 'rounded px-3 py-2 flex items-center justify-center gap-2 ',
        iconAlone: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
